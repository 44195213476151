import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
    FirstScreen,
    ServicesAdvantages,
    HowItWorksReserve,
    Components,
    InteractiveReserve,
    StagesOfWork,
    Garants
} from '../components/ServicesAndPrices'
import { FormOrder, Breadcrumbs } from '../components/Global'
// content
import YAMLGlobal from "../content/ru/global.yaml"
import YAMLService from "../content/ru/service.yaml"

const ServicesReservePage = ({location}) => {    
    const serviceLocal = {
        langBase: "",
        YAMLGlobal,
        YAMLContent: YAMLService
    }
    
    const data = useStaticQuery(graphql`
        query {
            seo: directusPricesReserveSeo {
                title
                description
            }
            firstscreen: allDirectusPricesFirstscreen {
                edges {
                    node {
                        reserve_description
                        reserve_title
                        reserve_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1600) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            advantages: allDirectusPricesReserveAdvantage {
                edges {
                    node {
                        id
                        title
                        description
                    }
                }
            },
            slides: allDirectusPricesReserveComponent {
                edges {
                    node {
                        id
                        description
                        title
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        logos {
                            id
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            stages: allDirectusStepsWorkRu(sort: {fields: sort}) {
                edges {
                    node {
                        id
                        list
                        sort
                        title
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 700) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            interactive: allDirectusPricesservicesreserve {
                edges {
                    node {
                        reserve_price
                    }
                }
            },
            garants: allDirectusPricesGuarantee {
                edges {
                    node {
                        id
                        description
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    
    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/services-home',
            title: YAMLGlobal.menu.services.reserve
        }
    ]
    
    return(
        <Layout local={serviceLocal} location={location}>
            <SEO title={data.seo.title} description={data.seo.description} />
            <Breadcrumbs list={list}/>
            <FirstScreen content={{
                title: data.firstscreen.edges[0].node.reserve_title,
                text: data.firstscreen.edges[0].node.reserve_description,
                img: data.firstscreen.edges[0].node.reserve_image.localFile?.childImageSharp.fluid
            }} />
            <ServicesAdvantages advantages={data.advantages.edges}/>
            <HowItWorksReserve/>
            <Components background="gray"  slides={data.slides.edges}/>
            <InteractiveReserve interactive={data.interactive.edges[0].node}/>
            <StagesOfWork stages={data.stages.edges}/>
            <Garants waveMob={true} garants={data.garants.edges}/>
            <FormOrder wave="white"/>
        </Layout>
    )
}

export default ServicesReservePage;